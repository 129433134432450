import { Accordion, AccordionItemTypes } from "@components/Accordion";
import Big from "big.js";
import React from "react";

import { FundraiserResponse } from "@every.org/common/src/codecs/entities";
import {
  EVERYDOTORG_NONPROFIT_IDS,
  FUND_ACCOUNT_NONPROFIT_ID,
  MYRIAD_NONPROFIT_ID,
  UNDP_NONPROFIT_ID,
} from "@every.org/common/src/entity/constants";
import {
  Currency,
  DisbursementTypeUserFacing,
  DonationFlowPaymentOption,
} from "@every.org/common/src/entity/types";
import {
  getRoutePath,
  URLFormat,
  ClientRouteName,
} from "@every.org/common/src/helpers/clientRoutes";
import { displayCurrencyValue } from "@every.org/common/src/helpers/currency";

import { Link } from "src/components/Link";
import { getTaxDeductibleStatement } from "src/components/donate/DonateV3/Footer";
import { useParentNonprofit } from "src/context/NonprofitsContext/hooks";
import { ContextNonprofit } from "src/context/NonprofitsContext/types";
import { DonateV3GridCard } from "src/pages/DonateV3/styles";
import { hasParentId } from "src/pages/Nonprofit";
import { LinkAppearance } from "src/styles/link";
import { mailToLink } from "src/utility/helpers";

const MYRIAD_EMAIL = "info@myriadusa.org";
const MYRIAD_PHONE = "(212) 713 7660";
const UNDP_EMAIL = "updates.reply@undp.org";

export function getGranteeName(
  nonprofit: ContextNonprofit,
  parentNonprofit?: ContextNonprofit
) {
  const name = nonprofit.metadata?.prefixWithThe
    ? `the ${nonprofit.name}`
    : nonprofit.name;
  if (hasParentId(nonprofit, MYRIAD_NONPROFIT_ID)) {
    return `the American Friends Fund set up for ${name} hosted at Myriad USA`;
  }
  if (parentNonprofit) {
    return `the fund for ${name} hosted at ${parentNonprofit.name}`;
  }
  return name;
}

function getDisbursementDescription(
  nonprofit: ContextNonprofit,
  parentNonprofit?: ContextNonprofit
) {
  const granteeName = getGranteeName(nonprofit, parentNonprofit);

  if (
    nonprofit.disbursementType === DisbursementTypeUserFacing.STRIPE_CONNECT
  ) {
    return <span>On a weekly basis, Every.org grants to {granteeName}.</span>;
  }

  if (nonprofit.disbursementType === DisbursementTypeUserFacing.MANUAL) {
    return (
      <span>
        Every.org grants directly to {granteeName} on a regular basis.
      </span>
    );
  }

  const isPayPal =
    nonprofit.disbursementType === DisbursementTypeUserFacing.PAYPAL_GRANTS;

  return (
    <span>
      We then partner with {isPayPal ? "PayPal Grants" : "Network for Good"} to
      grant to {granteeName} on a{" "}
      <Link
        appearance={LinkAppearance.HYPERLINK_UNCOLORED}
        to={getRoutePath({
          name: ClientRouteName.DISBURSEMENTS,
          format: URLFormat.RELATIVE,
          fragment: "timing",
        })}
        data-tname={"DonationFaq--mounthlyDonate"}
      >
        monthly basis
      </Link>
      {isPayPal ? " (or semiannually for balance under $100)." : "."}
    </span>
  );
}

function getNfgDisclaimer(
  nonprofit: ContextNonprofit,
  parentNonprofit?: ContextNonprofit
) {
  const granteeName = getGranteeName(nonprofit, parentNonprofit);
  if (
    nonprofit.id !== FUND_ACCOUNT_NONPROFIT_ID &&
    (!nonprofit.disbursementType ||
      nonprofit.disbursementType === DisbursementTypeUserFacing.NFG_BATCH_FILE)
  ) {
    return (
      <p>
        Because {granteeName} has not added bank deposit info to Every.org yet,
        we currently grant to them through Network for Good, who charges a 2.25%
        disbursement fee.
      </p>
    );
  }
  return null;
}

function getEveryNoPlatformFee() {
  return (
    <p>
      Every.org does not charge any platform fee of our own, because we are a
      nonprofit. Instead, we rely on the generosity of donors to fund our
      mission of philanthropy for everyone.
    </p>
  );
}

function getFeeDescription(
  paymentOption: DonationFlowPaymentOption | undefined,
  nonprofit: ContextNonprofit,
  parentNonprofit?: ContextNonprofit
) {
  const granteeName = getGranteeName(nonprofit, parentNonprofit);
  switch (paymentOption) {
    case DonationFlowPaymentOption.PAYMENT_REQUEST:
    case DonationFlowPaymentOption.CREDIT_CARD:
      return (
        <React.Fragment>
          <p>
            Visa and Mastercard charge 2.2% + $0.30 for each transaction. Amex
            charges a 3.5% flat fee. There’s an additional 1% fee for non-US
            cards.
          </p>
          {getNfgDisclaimer(nonprofit, parentNonprofit)}
          {encourageBankDonation(nonprofit, parentNonprofit)}
          {getEveryNoPlatformFee()}
        </React.Fragment>
      );
    case DonationFlowPaymentOption.BANK:
      return (
        <React.Fragment>
          <p>
            Every.org currently covers the small fees for donations made with a
            bank, so 100% of your gift reaches {granteeName}.
          </p>
          {getEveryNoPlatformFee()}
        </React.Fragment>
      );
    case DonationFlowPaymentOption.PAYPAL:
      return (
        <React.Fragment>
          <p>
            PayPal charges 1.99% + $0.49 for each transaction. There’s an
            additional 1.5% fee for non-US donors.
          </p>
          {encourageBankDonation(nonprofit, parentNonprofit)}
          {getEveryNoPlatformFee()}
        </React.Fragment>
      );
    case DonationFlowPaymentOption.VENMO:
      return (
        <React.Fragment>
          <p>
            Venmo charges 1.99% + $0.49 for each transaction. There’s an
            additional 1.5% fee for non-US donors.
          </p>
          {getNfgDisclaimer(nonprofit, parentNonprofit)}
          {encourageBankDonation(nonprofit, parentNonprofit)}
          {getEveryNoPlatformFee()}
        </React.Fragment>
      );
    case DonationFlowPaymentOption.CRYPTO:
      return (
        <React.Fragment>
          <p>
            Our exchanges generally charge a 1% flat fee to automatically
            liquidate cryptocurrency. Every.org does not charge any fees of our
            own. For donations worth over $100k, you have the option to email
            crypto@every.org to arrange for manual exchange and a lower fee
            (usually .2% - .5%). But you are always welcome to immediately
            donate with the 1% fee.
          </p>
          {getNfgDisclaimer(nonprofit, parentNonprofit)}
          {getEveryNoPlatformFee()}
        </React.Fragment>
      );
    case DonationFlowPaymentOption.STOCKS:
      return (
        <React.Fragment>
          <p>
            Every.org covers all brokerage fees for stock donations, so usually
            no! We may not be able to accept all mutual funds, and when we can
            our brokerage charges us a small fee which will be deducted.
          </p>
          {getNfgDisclaimer(nonprofit, parentNonprofit)}
          {getEveryNoPlatformFee()}
        </React.Fragment>
      );
    case DonationFlowPaymentOption.DAF:
      return (
        <React.Fragment>
          <p>
            We do not charge any fees to accept DAF gifts, and currently cover
            all the Chariot fees.
          </p>
          {getNfgDisclaimer(nonprofit, parentNonprofit)}
          {getEveryNoPlatformFee()}
        </React.Fragment>
      );
    default:
      return (
        <React.Fragment>
          <p>There may be third-party fees.</p>
          {getNfgDisclaimer(nonprofit, parentNonprofit)}
          {getEveryNoPlatformFee()}
        </React.Fragment>
      );
  }
}

function encourageBankDonation(
  nonprofit: ContextNonprofit,
  parentNonprofit?: ContextNonprofit
) {
  const granteeName = getGranteeName(nonprofit, parentNonprofit);
  return (
    <p>
      Don’t like fees? Neither do we! Donate via bank and then 100% of your gift
      will reach {granteeName}.
    </p>
  );
}

export const FaqComponent = ({
  nonprofit,
  fundraiser,
  paymentOption,
  showStartFundraiserLink = true,
}: {
  nonprofit: ContextNonprofit;
  fundraiser?: FundraiserResponse;
  paymentOption: DonationFlowPaymentOption | undefined;
  showStartFundraiserLink?: boolean;
}) => {
  const parentNonprofit = useParentNonprofit(nonprofit);

  const isEverydotorgRecipient = EVERYDOTORG_NONPROFIT_IDS.includes(
    parentNonprofit?.id || nonprofit.id
  );

  const faqDataList: AccordionItemTypes[] = [
    ...(nonprofit?.id === UNDP_NONPROFIT_ID
      ? [
          {
            id: "undp-large-donation",
            title: "Are there special requirements for larger donations?",
            description: (
              <p>
                For donations of{" "}
                {
                  displayCurrencyValue({
                    value: { currency: Currency.USD, amount: new Big(5000) },
                  }).formatted
                }{" "}
                or above, please contact us directly before making your
                contribution. This allows us to ensure that your generous gift
                is processed correctly and directed to the area where it can
                make the most impact. For further assistance, please reach out
                to us at{" "}
                <Link
                  data-tname="undpEmailLink"
                  to={mailToLink({
                    address: UNDP_EMAIL,
                  })}
                  appearance={LinkAppearance.HYPERLINK_UNCOLORED}
                >
                  {UNDP_EMAIL}
                </Link>
                .
              </p>
            ),
          },
        ]
      : []),
    ...(parentNonprofit?.id === MYRIAD_NONPROFIT_ID
      ? [
          {
            id: "myriad-check-wire",
            title: "How do I donate via Check or Wire?",
            description: (
              <React.Fragment>
                <p>
                  For gifts by check: Write your check to Myriad USA, write
                  &quot;
                  {nonprofit.name}&quot; in the memo section of the check, and
                  send it to Myriad USA, 551 Fifth Avenue, Suite 2400, New York,
                  NY 10176.
                </p>
                <p>
                  Gifts by wire transfer or to contribute other types of
                  property: Email Myriad USA at:{" "}
                  <Link
                    data-tname="dafDonationEmailLink"
                    to={mailToLink({
                      address: MYRIAD_EMAIL,
                    })}
                    appearance={LinkAppearance.HYPERLINK_UNCOLORED}
                  >
                    {MYRIAD_EMAIL}
                  </Link>{" "}
                  or phone{" "}
                  <Link
                    data-tname="dafDonationEmailLink"
                    to={`tel:${MYRIAD_PHONE}`}
                    appearance={LinkAppearance.HYPERLINK_UNCOLORED}
                  >
                    {MYRIAD_PHONE}
                  </Link>
                  .
                </p>
              </React.Fragment>
            ),
          },
        ]
      : []),
    {
      id: "intro",
      title: "How does Every.org accept my donation?",
      description: (
        <React.Fragment>
          <p>
            Your donation is made to Every.org, a US 501(c)(3) public charity.
            Every.org will immediately send you a receipt by email.{" "}
            {!isEverydotorgRecipient &&
              getDisbursementDescription(nonprofit, parentNonprofit)}
          </p>
          {!isEverydotorgRecipient && (
            <p>
              This process ensures your eligibility for a tax deduction, enables
              you to consolidate your gift records, and reduces the burden on{" "}
              {getGranteeName(nonprofit, parentNonprofit)}.
            </p>
          )}
        </React.Fragment>
      ),
    },
    {
      id: "fees",
      title: "Are there any fees?",
      description: getFeeDescription(paymentOption, nonprofit, parentNonprofit),
    },
    {
      id: "receipt",
      title: "Will I receive a tax-deductible receipt for my donation?",
      description: (
        <React.Fragment>
          <p>
            Yes. After your donation payment is confirmed, you will immediately
            get a tax-deductible receipt emailed to you.
          </p>
          <p>
            {getTaxDeductibleStatement(nonprofit, fundraiser, parentNonprofit)}
          </p>
          <p>
            Additionally, if you have an Every.org account, you can always get a
            single itemized receipt that shows all your donations in a given
            year.
          </p>
        </React.Fragment>
      ),
    },
    {
      id: "p2p",
      mobileOnly: true,
      omit: !showStartFundraiserLink,
      title: `How else can I support ${nonprofit.name}?`,
      description: (
        <React.Fragment>
          <p>
            You can also rally your friends, family, and social networks to
            support this nonprofit by starting your own fundraiser for them.{" "}
            <Link
              appearance={LinkAppearance.HYPERLINK_UNCOLORED}
              to={getRoutePath({
                format: URLFormat.RELATIVE,
                name: ClientRouteName.FUNDRAISER_CREATE,
                tokens: { nonprofitSlug: nonprofit.primarySlug },
              })}
              data-tname={"DonationFaq--seeTerms"}
            >
              Start a fundraiser for {nonprofit.name}
            </Link>
          </p>
        </React.Fragment>
      ),
    },
  ];

  return (
    // key forces re-render whole component when paymentOption changes
    // this fixes the "Failed to execute 'removeChild' on 'Node': The node to be removed is not a child of this node." error
    // this error occurs when the user uses the Google Translate extension on the page (or any extension that modifies the DOM)
    // https://github.com/facebook/react/issues/11538
    <DonateV3GridCard key={paymentOption}>
      <Accordion dataList={faqDataList} />
    </DonateV3GridCard>
  );
};
